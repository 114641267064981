<template>
  <CCard>
    <CCardHeader> Impostazioni di ricerca </CCardHeader>
    <CCardBody>
      <!-- <input type="text" v-model="searchL"> -->
      <form @submit.prevent="handleSearch" method="post">
        <CRow>
          <CCol :md="3">
            <label for="datePickFM">Data degli incontri</label>
            <!-- <datepicker v-model="datePickFM" class="cursor-pointer" id="datePickFM" name="dateSearch" /> -->
            <input
              name="searchDate"
              type="Date"
              class="form-control"
              placeholder="Data"
              v-model="datePickFM"
            />
            <!-- <ErrorMessage name="searchDate" class="error-feedback" /> -->
          </CCol>
          <CCol :md="3">
            <input
              name="searchLeague"
              type="text"
              class="form-control"
              placeholder="Cerca Lega"
              v-model="searchL"
            />
            <select name="search" class="form-control" v-model="selected">
              <option
                v-for="(league, indL) in result"
                :key="indL"
                :value="JSON.stringify(league)"
              >
                {{ league["item"] }}
              </option>
            </select>
            <!-- <ErrorMessage name="search" class="error-feedback" /> -->
          </CCol>
          <CCol :md="3">
            <input
              name="searchCampionato"
              type="text"
              class="form-control"
              placeholder="Cerca Campionato"
              v-model="searchC"
            />
            <select name="searchC" class="form-control" v-model="selectedC">
              <option
                v-for="(camp, indC) in resultC"
                :key="indC"
                :value="JSON.stringify(camp)"
              >
                {{ camp["item"]["name"] }}
              </option>
            </select>
            <!-- <ErrorMessage name="search" class="error-feedback" /> -->
          </CCol>
          <CCol :md="3">
            <CButton color="primary" class="px-4" :disabled="loading">
              <span v-show="loading" class="spinner-border spinner-border-sm"></span>
              <span>Cerca</span>
            </CButton>
          </CCol>
        </CRow>
      </form>
    </CCardBody>
  </CCard>
  <CAccordion>
    <CAccordionItem :item-key="1" v-if="searchedData['leagueData']">
      <CAccordionHeader> Dati della lega </CAccordionHeader>
      <CAccordionBody>
        <CRow class="bordo head">
          <CCol :md="3">
            <div>ID</div>
          </CCol>
          <CCol :md="3">
            <div>Name</div>
          </CCol>
          <CCol :md="3">
            <div>CC</div>
          </CCol>
          <CCol :md="3"> </CCol>
        </CRow>
        <CRow class="bordo">
          <CCol :md="3">
            <div>{{ searchedData["leagueData"]["id"] }}</div>
          </CCol>
          <CCol :md="3">
            <div>{{ searchedData["leagueData"]["name"] }}</div>
          </CCol>
          <CCol :md="3">
            <div>{{ searchedData["leagueData"]["cc"] }}</div>
          </CCol>
          <CCol :md="3">
            <a
              class="btn btn-success"
              href="javascript:void(0)"
              @click="extendData(searchedData['leagueData'])"
              >Estendi</a
            >
          </CCol>
        </CRow>
      </CAccordionBody>
    </CAccordionItem>
    <CAccordionItem :item-key="2" v-if="searchedData['cmp']">
      <CAccordionHeader> Leghe in base al Campionato </CAccordionHeader>
      <CAccordionBody>
        <CRow class="bordo head">
          <CCol :md="3">
            <div>ID</div>
          </CCol>
          <CCol :md="3">
            <div>Name</div>
          </CCol>
          <CCol :md="3">
            <div>CC</div>
          </CCol>
          <CCol :md="3"> </CCol>
        </CRow>
        <CRow v-for="(league, indexL) in searchedData['cmp']" :key="indexL" class="bordo">
          <CCol :md="3">
            <div>{{ league["id"] }}</div>
          </CCol>
          <CCol :md="3">
            <div>{{ league["name"] }}</div>
          </CCol>
          <CCol :md="3">
            <div>{{ league["cc"] }}</div>
          </CCol>
          <CCol :md="3">
            <a
              class="btn btn-success"
              href="javascript:void(0)"
              @click="extendData(league)"
              >Estendi</a
            >
          </CCol>
        </CRow>
      </CAccordionBody>
    </CAccordionItem>
    <CAccordionItem :item-key="3" v-if="searchedData['ended_events']">
      <CAccordionHeader> Eventi Finiti </CAccordionHeader>
      <CAccordionBody :class="'over'">
        <CRow>
          <CCol :md="6">Cerca tra eventi finiti</CCol>
          <CCol :md="6"><input type="text" v-model="searchE" name="searchEF" placeholder="Cerca..." /></CCol>
        </CRow>
        <CRow :class="'bordo head'">
          <CCol :md="1">ID</CCol>
          <CCol :md="4">Match</CCol>
          <CCol :md="1">HT</CCol>
          <CCol :md="1">FT</CCol>
          <CCol :md="2">Filtro</CCol>
          <CCol :md="2"></CCol>
        </CRow>
        <CRow v-for="(item, indItem) in resultE" :key="indItem" :class="'bordo'">
          <CCol :md="1" v-if="item.item.id != undefined">{{item.item?.id}}</CCol>
          <CCol :md="4" >{{item.item?.home?.name}} vs {{item.item?.away?.name}}</CCol>
          <CCol :md="1" v-if="item.item?.scores != undefined && Object.keys(item.item?.scores).includes('1') || false">{{item.item?.scores['1']['home']}}:{{item.item?.scores['1']['away']}}</CCol><CCol v-else :md="1">-</CCol>
          <CCol :md="1" v-if="item.item.scores != undefined && Object.keys(item.item.scores).includes('2') || false">{{item.item.scores['2']['home']}}:{{item.item.scores['2']['away']}}</CCol><CCol v-else :md="1">-</CCol>
          <CCol :md="4">
            <CFormCheck inline :id="'PX'+item.item.id" value="option1" label="PX"/>
            <CFormCheck inline :id="'CX'+item.item.id" value="option2" label="CX"/>
            <CFormCheck inline :id="'FX'+item.item.id" value="option1" label="FX"/>
            <CFormCheck inline :id="'U3'+item.item.id" value="option2" label="U3"/>
            <CFormCheck inline :id="'AVG'+item.item.id" value="option2" label="AVG"/>
          </CCol>
          <CCol :md="1"><a href="javascript:void(0)" class="btn btn-secondary" @click="clickHandle(item.item)">Inserisci</a></CCol>
        </CRow>
        <CCards v-if="resultE.length == 0">
          <CCard style="border: none;">
            <CRow v-for="(game, indGame) in searchedData['ended_events']" :key="indGame" :class="'bordo'">
              <CCol :md="1" v-if="game.id != undefined">{{game?.id}}</CCol>
              <CCol :md="4" >{{game?.home?.name}} vs {{game?.away?.name}}</CCol>
              <CCol :md="1" v-if="game.scores != undefined && Object.keys(game.scores).includes('1') || false">{{game.scores['1']['home']}}:{{game.scores['1']['away']}}</CCol><CCol v-else :md="1">-</CCol>
              <CCol :md="1" v-if="game.scores != undefined && Object.keys(game.scores).includes('2') || false">{{game.scores['2']['home']}}:{{game.scores['2']['away']}}</CCol><CCol v-else :md="1">-</CCol>
              <CCol :md="4">
              <CFormCheck inline :id="'PX'+game.id" value="option1" label="PX"/>
              <CFormCheck inline :id="'CX'+game.id" value="option2" label="CX"/>
              <CFormCheck inline :id="'FX'+game.id" value="option1" label="FX"/>
              <CFormCheck inline :id="'U3'+game.id" value="option2" label="U3"/>
              <CFormCheck inline :id="'AVG'+game.id" value="option2" label="AVG"/>
              </CCol>
              <CCol :md="1"><a href="javascript:void(0)" class="btn btn-secondary" @click="clickHandle(game)">Inserisci</a></CCol>
            </CRow>
          </CCard>
        </CCards>
      </CAccordionBody>
    </CAccordionItem>
  </CAccordion>
</template>

<script>
import { watch, ref, computed } from "vue";
import Datepicker from "vue3-datepicker";
// import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
// import { useStore } from 'vuex';
import Fuse from "fuse.js";
// import { useFuse } from '@vueuse/integrations/useFuse'

import { getData } from "../../plugins/fetch";
import { useStore } from "vuex";

export default {
  name: "Filtro manuale",
  components: {
    Datepicker,
    // Form,
    // Field,
    // ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      search: yup.object(),
      searchDate: yup.string(),
      searchLeague: yup.string(),
    });
    return {
      loading: false,
      message: "",
      schema,
      allowed: [],
    };
  },
  setup() {
    const datePickFM = ref(new Date());
    const searchL = ref("");
    const searchC = ref("");
    const searchE = ref("");
    const selected = ref({});
    const selectedC = ref({});
    const store = useStore();
    const fuse3 = ref(null);

    const optionsE = {
      isCaseSensitive: false,
      // includeScore: false,
      // shouldSort: true,
      // includeMatches: false,
      // findAllMatches: false,
      // minMatchCharLength: 1,
      // location: 0,
      // threshold: 0.6,
      // distance: 100,
      // useExtendedSearch: false,
      // ignoreLocation: false,
      // ignoreFieldNorm: false,
      // fieldNormWeight: 1,
      keys: [
          "home.name",
          "away.name",
          "league.name"
      ]
    };

    const searchedData = computed(() => {
      fuse3.value = new Fuse(store.state.searchedData.ended_events, optionsE);
      return store.state.searchedData;
    });
    // store.dispatch("getLeagues");
    // const allowedjar = computed(() => store.state.allowedjar);

    const options = {
      isCaseSensitive: false,
      // includeScore: false,
      // shouldSort: true,
      // includeMatches: false,
      // findAllMatches: false,
      // minMatchCharLength: 1,
      // location: 0,
      // threshold: 0.6,
      // distance: 100,
      // useExtendedSearch: false,
      // ignoreLocation: false,
      // ignoreFieldNorm: false,
      // fieldNormWeight: 1,
      // keys: [
      //     "name"
      // ]
    };
    const optionsC = {
      isCaseSensitive: false,
      // includeScore: false,
      // shouldSort: true,
      // includeMatches: false,
      // findAllMatches: false,
      // minMatchCharLength: 1,
      // location: 0,
      // threshold: 0.6,
      // distance: 100,
      // useExtendedSearch: false,
      // ignoreLocation: false,
      // ignoreFieldNorm: false,
      // fieldNormWeight: 1,
      keys: ["name"],
    };
    const result = ref([]);
    getData("https://api.soccerstandings.online/get-allowed").then((allowedjar) => {
      const fuse = new Fuse(allowedjar, options);
      watch(searchL, (text) => {
        result.value = fuse.search(text);
      });
    });
    const resultC = ref([]);
    getData("https://api.soccerstandings.online/get_campionati").then((campionati) => {
      const camps = JSON.parse(campionati);
      const fuse2 = new Fuse(camps["countries"], optionsC);
      watch(searchC, (textC) => {
        resultC.value = fuse2.search(textC);
      });
    });
    const resultE = ref([]);
    watch(searchE, (textE) => {
      setTimeout(() => {
        resultE.value = fuse3.value.search(textE);
      }, 1500);
    })

    return {
      datePickFM,
      result,
      searchL,
      searchE,
      selected,
      searchC,
      selectedC,
      resultC,
      store,
      searchedData,
      resultE,
    };
  },
  methods: {
    extendData(data) {
      alert(JSON.stringify(data));
    },
    handleSearch(data) {
      const elements = data.target;
      const date = elements[0].value;
      let campionato = null;
      let lega = null;

      try {
        campionato = JSON.parse(elements[4].value);
      } catch (error) {}
      try {
        lega = JSON.parse(elements[2].value);
      } catch (error1) {}

      this.store.dispatch("sendSearch", {
        date: date,
        league: lega,
        campionato: campionato,
      });
      // getData("https://api.soccerstandings.online/get-allowed").then((allowedjar) => {
      //     this.allowed = allowedjar;
      // });
    },
    filterHelper(data){
      const date = ('0' + data.date.getDate()).slice(-2);
      const month = ('0' + (data.date.getMonth() + 1)).slice(-2);
      const year = data.date.getFullYear();
      const hours = ('0' + data.date.getHours()).slice(-2);
      const minutes = ('0' + data.date.getMinutes()).slice(-2);
      const formated = `${date}/${month}/${year}`;
      return {
        eventid: data?.eventid,
        match: data?.match,
        filter_name: data?.filter_name,
        filter_id:  data?.filter_id,
        date: formated,
        time: `${hours}:${minutes}`,
        league: data?.league,
        team_filtered: data?.team_filtered
      }
    },
    U3filter_helper(data){
      const date = ('0' + data.date.getDate()).slice(-2);
      const month = ('0' + (data.date.getMonth() + 1)).slice(-2);
      const year = data.date.getFullYear();
      const hours = ('0' + data.date.getHours()).slice(-2);
      const minutes = ('0' + data.date.getMinutes()).slice(-2);
      const formated = `${date}/${month}/${year}`;
      return {
        eventid: data?.eventid,
        match: data?.match,
        filter_name: data?.filter_name,
        filter_id:  data?.filter_id,
        date: formated,
        time: `${hours}:${minutes}`,
        team_id: data?.team_id,
        league_id: data?.league_id,
        league_name: data?.league_name,
        team_name: data?.team_name,
        next_date: data?.next_date
      }
    },
    clickHandle(item){
      const eventid = item.id;
      const match = item.home.name + " - " + item.away.name;
      const score = item.ss;
      const date = new Date(parseInt(item.time)*1000);
      const px = document.getElementById("PX"+eventid).checked;
      const fx = document.getElementById("FX"+eventid).checked;
      const cx = document.getElementById("CX"+eventid).checked;
      const U3 = document.getElementById("U3"+eventid).checked;
      const AVG = document.getElementById("AVG"+eventid).checked;
      const filters = [{check: px, name: "PX", id: 1}, {check: cx, name: "CX", id: 2}, {check: fx, name: "FX", id: 3}];
      filters.forEach(filter => {
        if(filter.check){
          if(filter.name == "PX"){
            let team = prompt("Quale squadra passa il filtro?\nScegli tra i due.\n"+match, match);
            if(team !== null) {
              this.store.dispatch("insert_filter", this.filterHelper(
                {
                  eventid: eventid,
                  match: match,
                  filter_name: filter.name,
                  filter_id:  filter.id,
                  date: date,
                  time: date,
                  league: [item.league.name, item.league.id],
                  team_filtered: team.trim()
                }
              ))
            }
          } else {
            this.store.dispatch("insert_filter", this.filterHelper(
              {
                eventid: eventid,
                match: match,
                filter_name: filter.name,
                filter_id:  filter.id,
                date: date,
                time: date,
                league: [item.league.name, item.league.id],
              }
            ))
          }

      }
      });
      if(AVG){
        const avg = prompt("Inserisci il valore della media gol");
        const percht = prompt("Inserisci la percentuale di gol HT");
        const homeGoals = prompt("Inserisci i gol fatti dalla squadra di casa");
        const awayGoals = prompt("Inserisci i gol fatti dalla squadra fuori casa");
        const t = new Date(parseInt(item.time)*1000);
        const date = ('0' + t.getDate()).slice(-2);
        const month = ('0' + (t.getMonth() + 1)).slice(-2);
        const year = t.getFullYear();
        const time = `${year}/${month}/${date}`;
        const payload = {
          filter_name: "AVG",
          date: time,
          eventid: item.id,
          avg: parseFloat(avg),
          percht: parseFloat(percht),
          homegoals: homeGoals,
          awaygoals: awayGoals,
          eventview: item
        }
        this.store.dispatch("insert_filter", payload);
      }
      if(U3){
        const next_date = prompt("Insertisci la data dello scontro nel formato YYYYMMDD", "20220131");
        if(next_date != ""){
          this.store.dispatch("insert_filter", this.U3filter_helper(
            {
              eventid: eventid,
              match: match,
              filter_name: "U3",
              filter_id:  4,
              date: date,
              time: date,
              league_id: item.league.id,
              league_name: item.league.name,
              team_id: item.home.id,
              team_name: item.home.name,
              next_date: next_date
            }
          ))
        }
      }
    }
  },
  mounted() {
    // getData("https://api.soccerstandings.online/get-allowed").then((allowedjar) => {
    //     this.allowed = allowedjar;
    // });
  },
};
</script>

<style>
.cursor-pointer {
  cursor: pointer;
  text-align: center;
}
.bordo {
  border-bottom: 1px solid black;
}
.bordo div {
  padding-top: 3px;
  padding-bottom: 3px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.head {
  font-weight: 500;
}
.over {
  height: 500px;
  overflow-y: auto;
}
</style>
